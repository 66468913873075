<template>
  <div class="columns is-multiline has-text-centered">
    <div class="column is-8 is-paddingless is-marginless has-text-left titleCard">
      Vendedor
    </div>
    <div class="column is-4 is-paddingless is-marginless has-text-right">
      <button class="button is-success" @click="showModalMessage">
      <i class="material-icons click">chat</i>
      </button>
    </div>
    <div class="column is-12 separator"></div>
    <div class="column is-12">
      <img :src="detail.userImage" class="userImage"/>
    </div>
    <div class="column is-12 is-paddingless">
      <router-link :to="{ name: 'detalle-usuario', params: { userId: detail.userId } }">
        <strong class="userName">{{detail.name}}</strong>
      </router-link>
    </div>
    <div class="column is-12 is-paddingless">
      <p class="calificacion">
        <template v-for="i in 5">
        <i class="material-icons selected" :key="i" v-if="i <= detail.score || 0">star</i>
        <i class="material-icons" :key="i" v-else>star_border</i>
        </template>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    detail: {
      type: Object,
      required: true
    },
    showModalMessage: {
      type: Function,
      default: () => {}
    }
  }
}
</script>

<style scoped>
  .userImage {
    width: 100px;
    height: 100px;
    background-position: 0;
    border-radius: 50%;
  }
  .calificacion {
    color: var(--primary-color);
  }
  .calificacion .material-icons {
    font-size: 18px;
  }
</style>
